import React from 'react';
import PropTypes from 'prop-types';

const DiscordWidget = ({ channelId, theme }) => {
  const src = `https://discord.com/widget?id=${channelId}&theme=${theme}`;
  return (
    <iframe
      src={src}
      width="400"
      height="300"
      allowTransparency="true"
      frameBorder="0"
      sandbox="allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts"
      style={{ display: 'block', margin: '0 auto' }} // Center the iframe
    ></iframe>
  );
};

DiscordWidget.propTypes = {
  channelId: PropTypes.string.isRequired,
  theme: PropTypes.string,
};

DiscordWidget.defaultProps = {
  theme: 'dark',
};

export default DiscordWidget;