import React, { useState, useEffect } from 'react';
import './BlogStyles.css';

const BlogPage = () => {
  const [blogPosts, setBlogPosts] = useState([]);

  useEffect(() => {
    const fetchBlogPosts = async () => {
      try {
        const response = await fetch('/blogPosts.json');
        const data = await response.json();
        setBlogPosts(data.posts);
      } catch (error) {
        console.error('Error fetching blog posts:', error);
      }
    };

    fetchBlogPosts();
  }, []);

  const handleReadMore = (fileName) => {
    window.open(`/blogs/${fileName}`, '_blank');
  };

  return (
    <div className="section bg-dark text-white py-5">
      <div className="container">
        <h2 className="title text-center mb-5">Our Blog</h2>
        <div className="row">
          {blogPosts.map((post, index) => (
            <div key={index} className="col-md-4 mb-4">
              <div className="card blog-card h-100">
                <div className="card-body">
                  <h5 className="card-title">{post.title}</h5>
                  <p className="card-text">{post.description}</p>
                  <p className="card-text"><small className="text-muted">Published on {post.date}</small></p>
                </div>
                <div className="card-footer bg-transparent border-0">
                  <button onClick={() => handleReadMore(post.fileName)} className="btn btn-primary">Read More</button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default BlogPage;