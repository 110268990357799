import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar';
import Header from './components/Header';
import AboutSection from './components/AboutSection';
import ServicesSection from './components/ServicesSection';
import ContactSection from './components/ContactSection';
import AIChat from './components/AIChat';
import BlogPost from './components/blogs/BlogPost';
import BlogPreview from './components/blogs/BlogPreview';
import BlogPage from './components/blogs/BlogPage';
import Footer from './components/Footer';

// Import styles from assets folder
import './assets/css/nucleo-icons.css';
import './assets/css/blk-design-system.css';
import './assets/demo/demo.css';

// Import custom styles
import './App.css';

const HomePage = () => (
  <>
    <Header />
    <ServicesSection />
    <AboutSection />
    <AIChat assistantId="asst_O6gICpzy92vCXBIMHtjjEjHZ"/>
    <BlogPreview />
    <ContactSection />
  </>
);

const App = () => {
  useEffect(() => {
    // Ensure the page starts at the top
    window.scrollTo(0, 0);

    // Initialize fade-in animation
    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('appear');
        }
      });
    }, { threshold: 0.1 });

    document.querySelectorAll('.fade-in').forEach(el => observer.observe(el));

    // Clean up function
    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <Router>
      <div style={{justifyContent: "center"}} className="App app-wrapper">
        <Navbar />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/blog" element={<BlogPage />} />
          <Route path="/blog/:fileName" component={<BlogPost />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
};

export default App;