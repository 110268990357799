import React, { useState, useEffect, useRef } from 'react';
import { Send, Maximize2, Minimize2, X } from 'lucide-react';
import './AIChat.css';
import chatGptLogo from '../assets/img/chatgpt.png';
import byteLion from '../assets/img/ByteLion.png';

// Import OpenAI configuration from a separate file
import { openai } from '../utils/openaiConfig';

const AIChat = ({ assistantId }) => {
  console.log('AIChat rendered');
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const [isTyping, setIsTyping] = useState(false);
  const [isMaximized, setIsMaximized] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [threadId, setThreadId] = useState(null);
  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const [showTooltip, setShowTooltip] = useState(false);
  const tooltipRef = useRef(null);
  
  const toggleTooltip = () => setShowTooltip(!showTooltip);
  
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (tooltipRef.current && !tooltipRef.current.contains(event.target)) {
        setShowTooltip(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);
  

  useEffect(() => {
    const initChat = async () => {
      if (!assistantId) {
        console.error('Assistant ID is not provided');
        return;
      }
      try {
        // Create a new thread for each user
        const thread = await openai.beta.threads.create();
        setThreadId(thread.id);

        // Add initial AI message to the thread
        await openai.beta.threads.messages.create(thread.id, {
          role: "assistant",
          content: "Hello! You got HaiGuy here. Got any questions about HAI Consulting?"
        });

        setMessages([{ text: 'Hello! You got HaiGuy here. Got any questions about HAI Consulting?', sender: 'ai' }]);
      } catch (error) {
        console.error('Error initializing chat:', error);
      }
    };
    initChat();
  }, [assistantId]);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const handleSend = async () => {
    if (input.trim() === '' || !threadId || !assistantId) {
      console.error('Missing required data:', { input: input.trim(), threadId, assistantId });
      return;
    }
  
    const userMessage = { text: input, sender: 'user' };
    setMessages(prevMessages => [...prevMessages, userMessage]);
    setInput('');
    setIsTyping(true);
  
    try {
      // Add the user's message to the thread
      await openai.beta.threads.messages.create(threadId, {
        role: "user",
        content: input
      });
  
      // Create and stream the run in one step
      const stream = openai.beta.threads.runs.stream(threadId, {
        assistant_id: assistantId
      });
  
      let aiResponse = "";
  
      stream
        .on('textCreated', (text) => {
          console.log('Text created');
          aiResponse = "";
        })
        .on('textDelta', (textDelta, snapshot) => {
          aiResponse += textDelta.value;
          setMessages(prevMessages => {
            const newMessages = [...prevMessages];
            if (newMessages[newMessages.length - 1].sender === 'ai') {
              newMessages[newMessages.length - 1].text = aiResponse;
            } else {
              newMessages.push({ text: aiResponse, sender: 'ai' });
            }
            return newMessages;
          });
        })
        .on('toolCallCreated', (toolCall) => {
          console.log(`Tool call created: ${toolCall.type}`);
        })
        .on('toolCallDelta', (toolCallDelta, snapshot) => {
          if (toolCallDelta.type === 'code_interpreter') {
            if (toolCallDelta.code_interpreter.input) {
              console.log('Code input:', toolCallDelta.code_interpreter.input);
            }
            if (toolCallDelta.code_interpreter.outputs) {
              console.log('Code output:');
              toolCallDelta.code_interpreter.outputs.forEach(output => {
                if (output.type === "logs") {
                  console.log(output.logs);
                }
              });
            }
          }
        });
  
      await stream.finalizeStream();
  
    } catch (error) {
      console.error('Error in AI response:', error);
    } finally {
      setIsTyping(false);
    }
  };

  const toggleMaximize = () => {
    setIsMaximized(!isMaximized);
    if (isCollapsed) setIsCollapsed(false);
  };

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
    if (isMaximized) setIsMaximized(false);
  };

  if (isCollapsed) {
    return (
      <button style={{zIndex: "100"}} onClick={toggleCollapse} className="haiguy-collapsed-button" aria-label="Open chat">
        <img src={byteLion}/>
      </button>
    );
  }

  return (
    <div className={`haiguy-chat-container ${isMaximized ? 'haiguy-maximized' : ''}`}>
      <div className="haiguy-chat-window">
        <div className="haiguy-chat-header">
          <div className="haiguy-chat-header-left">
            <div className="haiguy-chat-header-icon" role="img" aria-label="byteLion"><img style={{width: "50%"}}src={byteLion}/></div>
            <h2 className="haiguy-chat-header-title">HaiGuy</h2>
          </div>
          <div className="haiguy-chat-header-controls">
            <button onClick={toggleMaximize} className="haiguy-control-button" aria-label={isMaximized ? "Minimize" : "Maximize"}>
              {isMaximized ? <Minimize2 size={20} /> : <Maximize2 size={20} />}
            </button>
            <button onClick={toggleCollapse} className="haiguy-control-button" aria-label="Close">
              <X size={20} />
            </button>
          </div>
        </div>
        {!isCollapsed && (
          <div className="haiguy-chat-powered-by">
            <span>Powered by:</span>
            <img 
              style={{width: "8%"}}
              src={chatGptLogo} 
              alt="ChatGPT Logo" 
              className="haiguy-chat-gpt-logo" 
              title="Open AI"
              onClick={toggleTooltip}
              ref={tooltipRef}
            />
            
          </div>
        )}
        <div className="haiguy-chat-messages">
          {messages.map((message, index) => (
            <div
              key={index}
              className={`haiguy-message ${message.sender === 'user' ? 'haiguy-message-user' : 'haiguy-message-ai'}`}
            >
              {message.text}
            </div>
          ))}
          {isTyping && (
            <div className="haiguy-typing-indicator">
              Typing...
            </div>
          )}
          <div ref={messagesEndRef} />
        </div>
        <div className="haiguy-input-area">
          <div className="haiguy-input-container">
            <input
              type="text"
              value={input}
              onChange={(e) => setInput(e.target.value)}
              onKeyPress={(e) => e.key === 'Enter' && handleSend()}
              placeholder="Type your message..."
              className="haiguy-input"
            />
            <button
              onClick={handleSend}
              className="haiguy-send-button"
            >
              <Send size={20} />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AIChat;