import React from 'react';
import './Header.css'; // Make sure to create this CSS file

const Header = () => {
  return (
    <div className="page-header header-filter">
      <ul className="squares">
        {[1, 2, 3, 4, 5, 6, 7].map((num) => (
          <li key={`square-${num}`}></li>
        ))}
      </ul>
      <div className="container h-full flex items-center justify-center">
        <div className="content-center brand text-center">
          <div className="title-container">
            <h1 style={{userSelect: 'none'}} className="floating short text-4xl sm:text-5xl md:text-6xl font-bold mb-6">HAI</h1>
            <h1 style={{userSelect: 'none'}} className="floating long text-4xl sm:text-5xl md:text-6xl font-bold mb-6">Consulting</h1>
          </div>
          <h4 style={{userSelect: 'none'}}className="text-xl sm:text-2xl mb-8 px-4">
            Empowering Your People with Cutting-Edge AI Solutions
          </h4>
          <button 
            className="btn btn-primary btn-lg mt-4 fade-in" 
            onClick={() => document.getElementById('about').scrollIntoView({behavior: 'smooth'})}>
            Learn More
          </button>
        </div>
      </div>
    </div>
  );
};

export default Header;