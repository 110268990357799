import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';

const BlogPost = () => {
  const [content, setContent] = useState('');
  const { fileName } = useParams();

  useEffect(() => {
    fetch(`/components/${fileName}`)
      .then(response => response.text())
      .then(html => setContent(html));
  }, [fileName]);

  return (
    <div className="section py-5 bg-dark text-white">
      <div className="container">
        <div dangerouslySetInnerHTML={{ __html: content }} />
        <div className="mt-5">
          <Link to="/blog" className="btn btn-primary">Back to Blog</Link>
        </div>
      </div>
    </div>
  );
};

export default BlogPost;