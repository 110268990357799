import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin, faXTwitter, faInstagram } from '@fortawesome/free-brands-svg-icons';
import './Footer.css';
import anothropicLog from '../assets/img/anthropic.png';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="container">
        <div className="row">
          <div className="col-md-3">
            <h2 className="title">HAI Consulting LLC•</h2>
          </div>
          <div className="col-md-3">
            <ul className="nav">
              <li className="nav-item">
                <a href="#services" className="nav-link">Services</a>
              </li>
              <li className="nav-item">
                <a href="#about" className="nav-link">About</a>
              </li>
              <li className="nav-item">
                <a href="#contact" className="nav-link">Contact</a>
              </li>
              <li className="nav-item">
                <a href={process.env.PUBLIC_URL + '/pdfs/Privacy Policy.pdf'} className="nav-link" target="_blank" rel="noopener noreferrer">Privacy Policy</a>
              </li>
            </ul>
          </div>
          <div className="col-md-3">
            <h3 className="title">Follow us:</h3>
            <div className="btn-wrapper profile">
              {/* Social media buttons */}
              <a href="https://www.linkedin.com/company/hai-consulting-llc/" target="_blank" rel="noopener noreferrer" className="btn btn-linkedin" title="Linked-In account for HAI Consulting">
                <FontAwesomeIcon icon={faLinkedin} size="2x" />
              </a>
              <a href="https://x.com/Hai_Consultants" className="btn btn-instagram" target="_blank" title="X account for HAI Consulting">
                <FontAwesomeIcon icon={faXTwitter} size="2x" />
              </a>
            </div>
          </div>
          <div className="col-md-3">
            <div className="created-with-card">
              <h3 className="title">Created with:</h3>
              <p>Made with free template from Creative Tim: <a href="https://www.creative-tim.com/product/blk-design-system-react" target="_blank" rel="noopener noreferrer">Creative Tim</a></p>
              <div className="claude-info">
                <img style={{width: "10%"}} src={anothropicLog} alt="Claude logo" className="claude-logo" />
                <p>Claude for pair programming</p>
              </div>
              <p className="thank-you">Thanks for the awesome tools!</p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;