import React, { useEffect } from 'react';
import DiscordWidget from './DiscordWidget';
import './ContactSection.css'; // Import the custom CSS

const ContactSection = () => {
  useEffect(() => {
    const form = document.getElementById('contactForm');
    const submitButton = document.getElementById('submitButton');
    const successMessage = document.getElementById('successMessage');

    const handleSubmit = (event) => {
      event.preventDefault();

      submitButton.disabled = true;
      submitButton.innerHTML = 'Sending...';

      fetch(form.action, {
        method: form.method,
        body: new FormData(form),
        headers: {
          'Accept': 'application/json'
        }
      }).then(response => {
        if (response.ok) {
          form.reset();
          successMessage.style.display = 'block';
          submitButton.style.display = 'none';

          // Hide the success message after 3 seconds with fade-out effect
          setTimeout(() => {
            successMessage.classList.add('fade-out');
            setTimeout(() => {
              successMessage.style.display = 'none';
              successMessage.classList.remove('fade-out');
              submitButton.style.display = 'block';
            }, 1000); // Match the duration of the fade-out transition
          }, 3000);
        } else {
          response.json().then(data => {
            if (Object.hasOwn(data, 'errors')) {
              alert(data["errors"].map(error => error["message"]).join(", "));
            } else {
              alert("Oops! There was a problem submitting your form");
            }
          });
        }
      }).catch(error => {
        alert("Oops! There was a problem submitting your form");
      }).finally(() => {
        submitButton.disabled = false;
        submitButton.innerHTML = 'Send Message';
      });
    };

    form.addEventListener('submit', handleSubmit);

    // Cleanup event listener on component unmount
    return () => {
      form.removeEventListener('submit', handleSubmit);
    };
  }, []);

  return (
    <section className="section section-lg section-contact contact-section" id="contact">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-8 col-lg-6">
            <div className="card card-plain">
              <div className="card-header">
                <h5 className="text-on-back">Say Hai</h5>
              </div>
              <div className="card-body">
              <form action="https://formspree.io/f/manwolrw" method="POST" id="contactForm">
                <div className="form-group">
                  <label>Your Name</label>
                  <input type="text" name="name" className="form-control" placeholder="Name" required />
                </div>
                <div className="form-group">
                  <label>Email address</label>
                  <input type="email" name="email" className="form-control" placeholder="Email" required />
                </div>
                <div className="form-group">
                  <label>Business Name</label>
                  <input type="text" name="business" className="form-control" placeholder="Your Business" required />
                </div>
                <div className="form-group">
                  <label>Leave us a message</label>
                  <textarea name="message" className="form-control" rows="4" placeholder="Tell us about your project" required></textarea>
                </div>
                <div className="form-group">
                  <button type="submit" className="btn btn-primary btn-round" id="submitButton">Send Message</button>
                </div>
              </form>
                <div id="successMessage" style={{ display: 'none' }} className="alert alert-success mt-3">
                  Thank you for your message. We'll get back to you soon!
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-8 col-lg-4">
            <div className="info info-horizontal">
              <div className="icon icon-primary">
                <i className="tim-icons icon-mobile"></i>
              </div>
              <div className="description">
                <h4 className="info-title">Give us a ring</h4>
                <p> Hai Consulting<br />
                  +1 770-364-2664<br />
                  Mon - Fri, 8:00am-5:00pm EST
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactSection;